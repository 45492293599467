.logo {
    width: 120px;
    height: 31px;
    float: left;
    margin-right: 200px;
  }

  .logo:hover { cursor: pointer; }

  .site-layout-content {
    background: #fff;
    padding: 24px;
    min-height: 800px;
    overflow-y: scroll;
  }