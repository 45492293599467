.ant-carousel .slick-slide {
    text-align: center;
    height: 160px;
    line-height: 160px;
    background: #364d79;
    overflow: hidden;
}
  
.ant-carousel .slick-slide h3 {
  color: #fff;
}

.carousel-image { height: 300px !important; }
.slick-slide, .slick-active, .slick-current { height: 300px !important; }